import { mq, spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { Grid, GridItem } from '@thg-commerce/gravity-system'
import { ProductBlock } from '@thg-commerce/enterprise-components'
import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import { Button } from '@thg-commerce/gravity-elements'

import { Orientation } from './ProductsShowcase'

export const StyledGrid = styled(Grid)<{
  sideMargin: boolean
  hideBottomMargin?: boolean
  isSponsoredAdsBanner?: boolean
}>`
  align-items: flex-start;
  margin: ${(props) =>
    props.sideMargin ? `0 ${spacing(2)}` : `0 0 ${spacing(8)}`};

  ${(props) => props.isSponsoredAdsBanner && 'align-items: end'};
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding: ${(props) => (props.hideBottomMargin ? '0' : `${spacing(4)} 0`)};
  }
  margin: ${(props) => props.hideBottomMargin && '0'};
`

export const Title = styled.h2<{ alignment: HorizontalAlignment }>`
  ${Text('large1', 'alternate')};
  text-align: ${(props) => props.alignment};
  margin: ${spacing(2)} 0 ${spacing(1)};
`

export const LeftSideTitle = styled.h1<{ alignment: HorizontalAlignment }>`
  ${Text('large2', 'alternate')};
  text-align: ${(props) => props.alignment};
  margin: ${spacing(2)} 0 ${spacing(3)};
`

export const Subtitle = styled.p<{
  alignment: HorizontalAlignment
  subtitleTextAlign?: HorizontalAlignment
  margin?: string
  isSponsoredBanner?: boolean
}>`
  ${Text('bodyText', 'default')};

  text-align: ${(props) =>
    props.subtitleTextAlign ? props.subtitleTextAlign : props.alignment};
  margin: ${(props) => props.margin || '0'};
  color: ${(props) =>
    props.isSponsoredBanner && props.theme.colors.palette.greys.grey};
`

export const FirstSideGridItem = styled(GridItem)<{
  orientation?: Orientation
  isSponsoredBanner?: boolean
}>`
  height: ${(props) => props.isSponsoredBanner && '100%'};
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    ${(props) =>
      props.orientation !== Orientation.VERTICAL &&
      `margin-right: -${spacing(4)};`}
  }
`

export const StyledGridItem = styled(GridItem)<{
  orientation?: Orientation
  contentDisplay?: string
}>`
  max-width: 100%;
  ${(props) => props.contentDisplay && `display: ${props.contentDisplay};`}

  ${(props) =>
    props.orientation === Orientation.VERTICAL && `margin-top: -${spacing(6)};`}
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    ${(props) => props.contentDisplay === 'flex' && `width: 950px;`}
  }
`

export const StyledProductBlock = styled(ProductBlock)<{
  orientation?: Orientation
}>`
  height: 100%;
  ${(props) =>
    props.orientation === Orientation.VERTICAL &&
    `
    padding: ${spacing(1.5)}; 
    background-color: ${props.theme.colors.palette.greys.white}; 
    margin: 0 0 ${spacing(1)};`}
`

export const ProductBlockContainer = styled.div<{
  orientation?: string
  padding?: number
  width?: string
}>`
  height: 100%;
  ${(props) =>
    props.orientation === Orientation.VERTICAL &&
    `border: 1px solid ${props.theme.colors.palette.greys.light};`}
  ${(props) => props.padding && `padding:${spacing(1)};`}
  ${(props) => props.width && `width:${props.width};`}
`

export const StyledButton = styled(Button)`
  margin-top: ${spacing(2)};
`
